.greeting-card {
  min-height: 100vh;
  padding: 50px 0 0 0;

  .form {
    label {
      color: $primary;
    }

    input {
      height: 5vh;
    }

    textarea {
      height: 10vh;
    }

    input,
    textarea {
      &::placeholder {
        color: $fourthiary;
        opacity: 0.5;
      }
    }

    button {
      background-color: $secondary;
      color: #ffffff;
      border: 1px solid transparent;

      &:active,
      &:hover {
        filter: brightness(1.1);
        border: 1px solid $primary;
      }
    }
  }

  .msg-box {
    &-wrapper {
      height: 40vh;
      overflow-y: auto;
    }

    .avatar {
      width: 2rem;
      height: 2rem;
      background-color: $fourthiary;
      color: white;
      font-size: 1.25rem;
      text-transform: uppercase;
      padding-top: 0.5%;
      margin-top: 0.5%;
    }

    .name {
      font-weight: 400;
      text-transform: capitalize;
      color: $primary;
    }

    .date {
      font-size: 0.8rem;
      color: $fourthiary;
    }

    .msg {
      color: $fourthiary;
    }
  }
}
